.learner_section__h1 {
  display: block;
  font-size: 18px;
  color: #51247a;
  font-weight: bold;
  margin: 0;
  margin-left: 15px;
}
.box {
  display: flex;
  width: 10px;
  background: none;
  border: none;
  align-items: center;
  flex: 80%;
}
.weak__box {
  border-bottom: 2px solid #efedeb;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.topic {
  border-right: 2px solid #efedeb;
  height: 75px;
  display: flex;
  font-size: 16px;
  align-items: center;
  width: 110px;
  justify-content: center;
  flex: 20%;
  margin-right: 30px;
  word-break: break-word;
  padding: 5px;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
h1.heading__h1 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #51247a;
  margin-top: 40px;
  margin-bottom: 50px;
}
.outer__box {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.svg {
  width: 35px;
  margin-right: 30px;
  flex-shrink: 0;
}
.tooltip_container {
  display: block;
  font-size: 18px;
  margin-right: 10px;
  width: 280px;
  height: 100%;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: #999490 transparent;
}
.desc_font {
  font-size: 14px;
  padding-left: 5px;
}
.activity-row {
  flex-wrap: unset;
  margin-top: 5px;
}
.col-title {
  min-width: 144px;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.css-ja5taz-MuiTooltip-tooltip {
  width: 100%;
}
.font-weight-bold {
  font-weight: bold;
}

@media (min-width: 1000px) {
  .outer__box {
    display: flex;
    border: 0px solid #ccc;
    width: 96%;
    justify-content: center;
    align-items: flex-end;
    flex-shrink: 1;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0;
  }

  .parent_weak_box {
    display: flex;
    align-items: center;
    margin: 0px 2px;
  }

  .svg {
    flex-shrink: 1;
    cursor: pointer;
    margin: auto;
  }

  .weak__box {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    height: 230px;
    border: none;
  }
  .icon {
    margin-left: 0px;
    flex-shrink: 1;
    cursor: pointer;
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95px;
    flex-grow: 1;
    background: white 0% 0% no-repeat padding-box;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }
  .heading__h1 {
    margin-top: 40px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #51247a;
  }
  .topic {
    width: inherit;
    text-align: center;
    font-size: 14px;
    color: #000000;
    height: auto;
    border: none;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    flex-shrink: 1;
  }
  .outer__box:first-child {
    margin-bottom: 40px;
  }
}
