.dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070 !important;
  border-radius: 4px !important;
  width: 298px;
  max-width: 298px;
  height: 40px;
}

.dropdown .css-1ko62if-control:focus,
.dropdown .css-1ko62if-control,
.dropdown .css-b62m3t-container {
  outline: none !important;
  min-height: unset !important;
}

.dropdown .css-1mqolke-menu {
  margin-top: 0 !important;
}

.dropdown .css-b62m3t-container:focus-visible,
.dropdown .css-b62m3t-container:focus-within {
  outline: none;
}

.dropdown .css-b62m3t-container,
.dropdown .css-1ko62if-control,
.dropdown .css-6iumiq-control {
  cursor: pointer;
  min-height: unset !important;
  align-items: none;
  -webkit-align-items: none !important; // height: 20px;
}

.dropdown .css-319lph-ValueContainer {
  cursor: pointer;
  -webkit-align-items: none !important;
  align-items: center !important;
  font-size: small;
}

.dropdown .css-1hb7zxy-IndicatorsContainer {
  height: 23px;
}

.dropdown .css-6j8wv5-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

// .dropdown_option {
//   width: 298px;
//   max-width: 298px;
// }

.dropdown_option:hover {
  background-color: #ffffff;
  color: black;
  font-weight: bold;
}

.confirm_button {
  width: 105px;
  height: 32px;
  background: #51247a 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
}
.cancel_button {
  width: 93px;
  height: 32px;
  background: #999490 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  color: #ffffff;
}

.modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal_body {
  display: flex;
  flex-direction: column;
}

.dropdown:focus-within {
  border: 2px solid black !important;
}