.container_input {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  overflow: auto;
  text-align: start;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  max-width: 100%;
}
.outer_Container_Activity_timeline {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  border: 2px solid #51247a;
  border-radius: 3px;
  // height: 600px;
}

.icon_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
}

.icon_div label {
  cursor: pointer;
}

.icon_div label:hover,
.icon_hover:hover,
.icon_div:hover {
  color: #51247a !important;
  stroke: #51247a !important;
  cursor: pointer;
}

.panel_name {
  color: #51247a;
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
}

input#activity_name {
  display: flex;
  padding: 2px;
  width: 550px;
  max-width: 100%;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
}

input#weighting {
  display: flex;
  padding: 2px;
  width: 550px;
  max-width: 100%;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
}
input#link {
  display: flex;
  padding: 2px;
  width: 550px;
  max-width: 100%;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
}
input#description {
  display: flex;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  max-width: 80%;
}

.add_Icons_Activity_Timeline {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #999490 transparent;
  margin: 0px;
}

.general_Icon_Svg:hover,
.general_Icon_Svg:active,
.general_Icon_Svg:focus,
.video_Icon_Svg:hover,
.discussion_Icon_Svg:hover,
.assessment_Icon_Svg:hover,
.quiz_Icon_Svg:hover,
.exam_Icon_Svg:hover {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

.general_Icon_Svg:hover ~ .general_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}
.video_Icon_Svg:hover ~ .video_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

.discussion_Icon_Svg:hover ~ .discussion_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

.assessment_Icon_Svg:hover ~ .assessment_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

.quiz_Icon_Svg:hover ~ .quiz_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

.exam_Icon_Svg:hover ~ .exam_label {
  color: #51247a !important;
  fill: #51247a !important;
  cursor: pointer;
}

button.activity_Timeline_delete,
button.activity_Timeline_save {
  padding: 5px 20px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  border: none;
}

button.activity_Timeline_save {
  background: #51247a;
}

button.activity_Timeline_delete {
  background: #999490;
}

button.activity_Timeline_delete:hover,
button.activity_Timeline_save:hover {
  background: #51247a;
  padding: 5px 20px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
}
.horizontal_Line_Course_Activity_Timeline {
  border: 1px solid gray;
  margin-left: 10px;
  margin-right: 10px;
}
.Add_Activity_Input_boxes {
  width: 600px;
  max-width: 80%;
}
.description_And_Checkbox {
  display: flex;
  flex-direction: column;
  margin-left: inherit;
}

.description {
  display: flex;
  width: 350px;
  height: 200px;
}

.delete_And_Save_Button_Activity_Timeline {
  margin-top: 20px;
}

.display_calender {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  width: 550px;
  max-width: 100%;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 5px;
}

.dueDate_activityTimeline,
.dueDate_activityTimeline:focus {
  outline: none !important;
  border: 0;
  outline: 0;
  margin-top: 3px;
}

.calender_icon {
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}
.mark_as_important_Checkbox {
  white-space: nowrap;
}
.activity_icon_label {
  text-align: center;
  width: 100px;
}

input#activity_name:focus,
input#weighting:focus,
input#link,
textarea#description:focus {
  outline: none;
  box-shadow: none;
}
