.button__activity {
  width: 100px;
  height: 50px;
  margin-top: auto;
  background-color: #707070;
  border: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button__activity:hover:enabled {
  background-color: #51247a;
}
.button__activity:disabled {
  background-color: #999490;
}
.box__2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  flex-grow: 1;
  height: 204px;
  background: #efedeb 0% 0% no-repeat padding-box;
  border: 1px solid #999490;
  border-radius: 4px;
  flex-shrink: 1;
}
.weak__box__2 {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
}
.parent_weak_box_2 {
  display: flex;
  align-items: center;
  // flex-grow: 1;
  flex-shrink: 1;
}
.course_activity_panel_container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex-wrap: wrap;
}

.outer__box__2 {
  display: flex;
  border: 0px solid #ccc;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.topic__2 {
  width: inherit;
  text-align: center;
  font-size: 16px;
  color: #000000;
  height: auto;
  border: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  flex-shrink: 1;
}
.parent_weak_box_2:after {
  content: "";
  border: 2px solid #999490;
  background: #999490;
  width: 9%;
  z-index: -1;
  position: relative;
  height: 2px;
  margin-top: 27px;
}
.svg_Activity_Timeline {
  margin-top: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
// .cursor {
//   cursor: pointer;
// }
// .parent_outer_box_2:last-child{
//   display: none;
// }
.parent_outer_box_2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
}
.outer__box__2:last-child .parent_weak_box_2::after {
  display: none;
}

.modal_form {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 80vw;
  max-height: 30vh;
}
