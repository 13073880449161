.margin_left-30 {
  margin-left: 30px;
}

.week_config-item {
  border: none;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.learning__config-head {
  word-break: break-word;
  width: 185px;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  input {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
    border: none;
    font-size: 16px;
    font-weight: normal;
    padding: 0;
  }
  input:focus {
    outline: none;
  }
}
ul.week_config-list.list-group {
  margin-top: 25px;
}

.list-item {
  display: flex;
  flex-direction: row;
}

.heading__learn {
  margin-bottom: 25px;
}
.week_config-checkbox-label {
  margin-left: 12px;
  cursor: pointer;
}

/* The container */
.checkbox_container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
  width: 224px;
}
.checkbox_container.auto-populate {
  width: auto;
}
input.week_config_populate-checkbox {
  visibility: visible;
  height: 20px;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  z-index: 1;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  border-style: solid;
  border-color: #999490;
  border-width: 1px;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
  background-color: #999490;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
  width: 6.5px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin-top: -15%;
  margin-left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.learning__config-edit-panel.selected {
  border: 1px solid #51247a;
}
.learning_config-title {
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.learning__config-edit_icon {
  flex-shrink: 0;
  cursor: pointer;
}
.learning__config-save_icon {
  cursor: pointer;
}
.learning_config-title-border {
  border: 1px solid #51247a;
  padding: 4px 7px;
  border-radius: 4px;
}
.learning_config-title-border.error {
  border: 1px solid #e62645;
}
.learning__config-panel {
  display: flex;
  // margin-bottom: 20px;
  align-items: center;
}
.learning__config-panel-parent {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.learning__config-edit-panel {
  border-color: #707070;
  border-style: solid;
  border-width: 1px;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.learning_config-color-picker {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 20px;
  cursor: pointer;
}
.learning_config-color-picker:focus-visible {
  outline: double;
}
.learning_config-color-picker:hover {
  border: 2px solid;
}
.learning_config-color-picker.borders {
  border: 2px solid;
  border-color: black;
}
.learning_config-date-picker {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.learning_config-calendar {
  flex-shrink: 0;
  margin-left: 20px;
}
button.learning_config-button.disabled {
  background-color: #999490;
  border-color: #999490;
}
button.learning_config-button {
  display: block;
  background-color: #51247a;
  border-radius: 4px;
  border-style: solid;
  border-color: #51247a;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 16px;
  border-width: 1px;
}
button.learning_config-button:focus-visible {
  border: 2px solid #d644d6;
  outline: none;
}
button.learning_config-button:hover {
  background-color: #51247a;
}
.color-picker {
  top: 25px;
  background: white;
  z-index: 10;
  position: relative;
  border: 1px solid #51247a;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  width: 118px;
  flex-direction: row;
}

.swatch {
  margin: 3.5px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: none;
}
input.swatch-input {
  width: 20px;
  border-radius: 0px;
  height: 20px;
  appearance: none;
  position: relative;
}

.swatch.selected {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.swatch.selected:after {
  content: "";
  display: block;
  width: 5.5px;
  position: absolute;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin-top: -13%;
  margin-left: 0%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.swatch:hover {
  border: solid;
  border-width: 2px;
  border-color: #000000;
}
.swatch-input:hover {
  cursor: pointer;
}
.swatch.selected > .swatch-input:hover {
  cursor: default;
}
.swatch.selected:hover {
  border: none;
  border-width: 0px;
  border-color: #000000;
  cursor: default;
}
