@media (min-width: 1000px) {
  .insert-link-modal.modal .modal-dialog {
    width: 90%;
  }
}
.link_dropdown {
  margin-left: auto;
}

.multilevel_select {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multilevel-select.react-dropdown-tree-select {
  width: 95%;
}
