.heading__learn {
  align-items: center;
}

.hidden {
  display: none;
  visibility: hidden;
}

.ptr_none {
  pointer-events: none;
}

.ptr_all {
  pointer-events: all;
}

.p__label {
  margin: 0;
  letter-spacing: 0px;
  font-weight: lighter;
  color: #000000;
  opacity: 1;
}

.lj_config_container {
  margin-bottom: 20px;
}

.learningcomp {
  margin: 10px 30px 30px 30px;
}

.section_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline_div {
  display: flex;
  flex-direction: column;
}

.section_flex_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding_top_25 {
  padding-top: 25px;
}

.padding_top_20 {
  padding-top: 20px;
}

.section_flex_3 {
  display: flex;
  padding-top: 12px;
  justify-content: flex-start;
}

.label__total {
  height: 22px;
  font-size: 16px;
  width: 224px;
}

.section__h1 {
  display: inline;
  font-size: 24px;
  color: #51247a;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
}

.section {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
}

.number__input.error,
.timeline_number__input.error {
  border-color: #e62645;
}

.number__input,
.timeline_number__input {
  -moz-appearance: textfield;
  background-color: #ffffff;
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  width: 50px;
  height: 32px;
  padding: 3px 13px;
  font-size: 16px;
}

.text__input {
  -moz-appearance: textfield;
  background-color: #ffffff;
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  width: 200px;
  height: 32px;
  padding: 3px 13px;
  font-size: 16px;
}

.number__input::-webkit-outer-spin-button,
.number__input::-webkit-inner-spin-button,
.timeline_number__input::-webkit-outer-spin-button,
.timeline_number__input::-webkit-inner-spin-button,
.text__input::-webkit-outer-spin-button,
.text__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flex {
  display: flex;
}

.week_config_populate-checkbox_2 {
  background-color: #707070;
}

.week_config_populate-checkbox_2 {
  background-color: #707070;
}

.checkbox {
  width: 25px;
  position: relative;
  margin-left: 69px;
  margin-right: 5px;
}

.checkbox_input {
  width: 20px;
  background-color: #999490;
  border: #ffffff;
  border-radius: 2px;
  cursor: pointer;
}

.arrow__down {
  cursor: pointer;
}
.arrow_down.svg_rotated {
  cursor: pointer;
}

.checkbox label:hover:after {
  opacity: 0.5;
  height: 5px;
}

.learning_path-generate_button {
  background: #51247a;
  padding: 5px 20px;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  width: 190px;

  &:hover {
    cursor: pointer;
  }
}

.backto_panel-button,
.cancel-insert-link-button {
  background-color: #999490;
  border-radius: 4px;
  border-style: solid;
  border-color: #999490;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 20px;
  border-width: 1px;
}

.panel-sub-wrapper {
  border: 1px solid var(--unnamed-color-51247a);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #51247a;
  border-radius: 4px;
  opacity: 1;
  padding: 4px 0px;
  display: flex;
  align-items: center;
}

.panel-sub-wrapper.error {
  border: 1px solid #e62645;
}

.selected .add-sub-heading-btn {
  display: block !important;
}

.panel-sub-heading-item-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #51247a;
  border-radius: 4px;
  width: 93%;

  span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.add-item {
  .error {
    border: 1px solid #e62645;
  }

  svg {
    cursor: pointer;
    right: 25px;
    visibility: visible;
    display: block;
    float: right;
  }

  .removeLink,
  .removeLink:hover {
    width: 30px;
    margin-left: 5px;
    margin-top: 5px;
  }

  // .close-add-item {
  //   height: 20px;
  // }
  .addItem_icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  // .addItem_SaveIcon {
  //   width: 24px;
  // }
}

.insert-link-modal {
  input {
    border-radius: 3px;
  }

  .error {
    border: 1px solid #e62645;
    border-radius: 5px;
  }
}

.save-sub-heading {
  input {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-51247a);
    width: 32vw;
  }
}

.sub-heading::-webkit-scrollbar {
  width: 2px;
}

.sub-heading::-webkit-scrollbar-track {
  background-color: transparent;
}

.sub-heading::-webkit-scrollbar-thumb {
  background-color: #999490;
}

.sub-head-title,
.panel-sub-wrapper {
  input {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-51247a);
    width: 95%;
    text-overflow: ellipsis;
    font-weight: bold;
  }
}

.selected .sub-heading {
  padding: 0 5px 0 0;
  scrollbar-color: #999490 transparent;
  scrollbar-width: thin;
  overflow-y: auto;
}

.sub-heading {
  padding: 0 5px 0 0;
  scrollbar-color: #999490 transparent;
  scrollbar-width: thin;
  overflow-y: hidden;

  input:focus-visible,
  textarea:focus-visible,
  textarea:focus {
    outline: none;
    box-shadow: none;
  }

  .add-sub-heading-btn {
    display: none;
    margin: 0 16px;
  }

  button,
  button:hover {
    background: #51247a;
    border-radius: 4px;
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 20px;
  }

  // svg {
  //   cursor: pointer;
  //   margin-left: auto;
  //   visibility: visible;
  //   flex-shrink: 0;
  // }

  .panel_task-inputs {
    width: fit-content;
  }

  textarea {
    resize: none;
    border: none;
    width: 97%;
    min-height: 30px !important;
    line-height: 1;
  }

  svg {
    cursor: pointer;
    right: 12px;
    // margin-left: auto;
    visibility: visible;
    flex-shrink: 0;
    float: right;
  }
}

textarea.form-control.sub_heading__input {
  width: 92%;
  text-overflow: ellipsis;
  font-weight: bold;
  border: none;
  font-size: 16px;
  color: #000000;
}

.backto_panel-button:hover,
.cancel-insert-link-button:hover {
  background-color: #51247a;
  // border: none;
}

// sub heading item css
.sub-heading-item-checkbox {
  visibility: visible;
  height: 17px;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  width: 17px;
  z-index: 1;
  cursor: pointer;
  border: 1px solid black;
  margin-top: 0;
}

.insert-link-option {
  select {
    width: 15vw;
  }
}

.insert-link-modal {
  .modal-footer {
    button {
      border-radius: 4px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .modal-title {
    color: #51247a;
    line-height: 0.5;
    font-size: larger;
  }

  .apply-button,
  .apply-button:hover {
    border-radius: 4px;
    letter-spacing: 0px;
    background: var(--unnamed-color-51247a) 0% 0% no-repeat padding-box;
    background: #51247a 0% 0% no-repeat padding-box;
    color: #fff;
    font-weight: bold;
    padding: 5px 20px;
    font-size: 16px;
    border: 1px #51247a;
  }
}

.sub-heading-item-link,
.sub-heading-item-link:hover {
  color: rgb(150, 42, 139);
  text-decoration: underline;
}

.link-wrapper {
  cursor: pointer;

  span {
    cursor: pointer;
  }

  button {
    float: right;
    border-radius: 116%;
    background: #7b7883;
    line-height: 5px;
    border-inline-style: none;
    width: 8px;
  }

  small {
    margin: -3px;
  }

  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}

//Course_Activity_timeline
.outer_wrap {
  margin-top: 65px;
  margin-left: 30px;
  margin-bottom: 45px;
  text-align: left;
}

.section__h2 {
  display: inline;
  font-size: 24px;
  color: #51247a;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
}

svg.arrow__down__2 {
  cursor: pointer;
}

div#learning_path-select-values {
  text-align: start;
}

label.label__Date {
  font-weight: bold;
}

label.date__sepration {
  font-weight: bold;
}

label.label__total__2 {
  font-weight: bold;
  width: 224px;
}

.checkbox_container_2 {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  margin-top: 19px;
  line-height: 20px;
  user-select: none;
  font-weight: normal;
}

.week_config-checkbox-label_2 {
  margin-left: 10px;
}

.learning_path-generate_button_2 {
  display: flex;
  align-items: center;
  background: #51247a;
  padding: 5px 20px;
  margin-top: 20px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  border: none;
}

.section_flex_2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

input.date__input__1:focus-visible {
  outline: none;
}

input.date__input__2:focus-visible {
  outline: none;
}

.date_input_wrapper {
  width: fit-content;
  border: 1px solid black;
}

.date_input_wrapper {
  height: 40px;
  border-radius: 4px;
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  width: 220px;
}

.label__Date {
  margin-top: 10px;
  font-weight: bold;
  width: 225px;
}

.all_wrapp {
  margin-top: 20px;
}

.calendar_icon_instructor {
  width: 40px;
}

.date__sepration {
  font-weight: bold;
  margin-top: 10px;
  margin-left: 48px;
  margin-right: 24px;
}

.calender__icon__front {
  margin-top: 9px;
  margin-left: 10px;
  width: 20px;
  margin-right: 10px;
}

.learning_config-date-course-activity-timeline {
  margin-top: 7px;
  font-weight: bold;
  margin-left: 20px;
}

.course_activity_date_range_container {
  display: flex;
  flex-direction: row;
}

.div_label_date_range,
.div_date,
.label_date_range,
.label_to {
  display: flex;
}

.div_date {
  align-items: flex-end;
}

.label_date_range,
.label_to {
  font-weight: bold;
  align-items: flex-end;
}

.label_date_range {
  margin-right: 60px;
}

.label_to {
  margin-left: 24px;
  margin-right: 24px;
}

.container_exam_orientaion input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container_exam_orientaion {
  display: block;
  margin-top: 20px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: 225px;
  font-weight: normal;
}

.checkmark_check {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
}

.container_exam_orientaion:hover input ~ .checkmark_check {
  background-color: #ccc;
}

.container_exam_orientaion input:checked ~ .checkmark_check {
  background-color: grey;
}
.container_exam_orientaion input:focus-visible ~ .checkmark_check {
  outline: auto;
  outline-width: thick;
}

.checkmark_check:after {
  content: "";
  position: absolute;
  display: none;
}

.container_exam_orientaion input:checked ~ .checkmark_check:after {
  display: block;
}

.container_exam_orientaion .checkmark_check:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
//Display calender checkbox
.Display_Calender {
  display: block;
  position: relative;
  font-weight: normal;
  padding-left: 35px;
  margin-left: 65px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.Display_Calender input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.Display_Calender:hover input ~ .checkmark {
  background-color: #ccc;
}

.Display_Calender input:checked ~ .checkmark {
  background-color: #a5a3a3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Display_Calender input:checked ~ .checkmark:after {
  display: block;
}

.Display_Calender .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.inside_dropdown {
  font-family: "OpenSans";
}

.sub-head-title {
  width: 100%;
  border: none;
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 4px 2px;
}

.sub-head-title.error {
  border: 1px solid #e62645 !important;
}

.sub-head-title.error:focus-within {
  border: 1px solid #e62645 !important;
}

.sub-head-title:focus-within {
  border: 1px solid #51247a;
}

.sub-head-title.border_ {
  border: 1px solid #51247a;
}

textarea.sub-head-heading {
  width: 92%;
  text-overflow: ellipsis;
  font-weight: bold;
  border: none;
  font-size: 16px;
  color: #000000;
  min-height: 30px !important;
}

// .selected textarea.sub-head-heading {
//   vertical-align: middle;
//   white-space: pre;
// }

textarea.sub-head-heading:focus {
  outline: none;
}

.sub-heading input:focus-visible {
  outline: none;
}

svg.sub-head-edit {
  display: none;
  fill: #51247a;
  width: 24px;
  height: 24px;
}

.selected .sub-head-title:hover svg.sub-head-edit {
  display: block;
  position: relative;
  right: 0;
}

.selected .sub-head-title:focus-within svg.sub-head-edit {
  display: block;
  position: relative;
  right: 0;
}

.selected .sub-head-title:focus-visible{
  outline: none;
}

.selected svg.sub-head-save {
  display: block;
  position: relative;
  right: 0;
}
.course_activity_outer_wrap {
  display: flex;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  appearance: none;
}

.no-spin {
  -moz-appearance: textfield;
}

.date_input {
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;

  input[type="number"] {
    border: none;
  }

  input[type="number"]:focus-visible {
    border: none;
    outline: none;
  }

  .day {
    width: 35px;
    text-align: center;
    font-weight: inherit;
  }

  .month {
    width: 45px;
    text-align: center;
    font-weight: inherit;
  }

  .year {
    width: 50px;
    text-align: center;
    font-weight: inherit;
  }
}

.date_input_wrapper.error {
  border-color: #e62645;
}

.svg_rotated {
  transform: rotate(270deg);
}

// dropdown customization
.form-select:focus {
  box-shadow: none;
  border-color: #eee;
}

// React select package component UI customization

.css-1ko62if-control:focus,
.css-1ko62if-control,
.css-b62m3t-container {
  outline: none !important;
  min-height: unset !important;
}

.css-b62m3t-container:focus-visible,
.css-b62m3t-container:focus-within {
  outline: none;
}

.css-b62m3t-container,
.css-1ko62if-control,
.css-6iumiq-control {
  cursor: pointer;
  min-height: unset !important;
  align-items: none;
  -webkit-align-items: none !important; // height: 20px;
}

.css-319lph-ValueContainer {
  cursor: pointer;
  -webkit-align-items: none !important;
  align-items: unset !important;
  font-size: small;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 23px;
}

.css-6j8wv5-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.button_class {
  display: flex;
  align-items: center;
}

.preview_button,
.course_copy_button {
  background: #ffffff;
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: #51247a;
  font-size: 16px;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  border: solid 1px #999490;
}

.course_copy_button {
  margin-right: 12px;
}

.preview_button.disabled {
  background: #999490;
  border: solid 1px #999490;
}
.item-container:hover .sub-head-edit {
  display: block;
}
.item-container:focus-within .sub-head-edit {
  display: block;
}

.item-title {
  width: 90%;
  word-wrap: break-word;
}

.instructor_banner {
  height: 100px;
  padding: 30px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 30px;
  font-weight: bold;
  background: #51247a;
  color: white;
  z-index: 40;
  top: 0;
  width: 100%;
}

.banner_header {
  margin-right: auto;
}
.subheading-text {
  width: 95%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected .subheading-text {
  overflow: visible;
  white-space: pre-wrap;
}
.learning_journey-wrap {
  margin-top: 65px;
}

.timer {
  margin-top: 110px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.arrow__down_path:focus-visible {
  outline: none;
  stroke: #a8a8a8;
  stroke-width: 2px;
  stroke-linejoin: round;
  border: none
}
.arrow__down_path:focus {
  border: none;
  outline: none;
}
.invalid_icon_size {
  width: 20px;
  height: 20px;
}
