.red {
  background-color: #962a8b;
  border-style: solid;
  border-width: 4px;
  border-color: #962a8b;
}

// .w2 {
//   background-color: #e62645;
//   border-style: solid;
//   border-width: 4px;
//   border-color: #e62645;
// }

// .w3 {
//   background-color: #fbb800;
//   border-style: solid;
//   border-width: 4px;
//   border-color: #fbb800;
// }

// .w4 {
//   background-color: #4085c6;
//   border-style: solid;
//   border-width: 4px;
//   border-color: #4085c6;
// }

.week_card-header.red,
.week_card-header.w2,
.week_card-header.w3,
.week_card-header.w4 {
  color: #ffffff;
}

.week_card {
  border-radius: 10px;
  width: 100%;
}

.week_card-header {
  font-size: 22px;
  font-weight: bold;
}

.week_card-text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  text-overflow: ellipsis;
}

form.todo__form {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.selected .week_card-text {
  display: block;
  white-space: normal;
  word-break: break-word;
}

.selected .week_card-body.card-body {
  overflow-y: auto;
}

.border-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-header:first-child {
  margin-left: -1px;
  margin-right: -1px;
}
.week_card-body.card-body {
  background-color: #ffffff;
  scrollbar-color: #999490 transparent;
  scrollbar-width: thin;
  overflow-y: auto;
  height: 330px;
  padding: 0px 10px;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
}

.todo_item {
  display: flex;
  align-items: flex-start;
  padding: 4px 0px;
}

.todo_itemlabel {
  width: 98%;
}

.todo_item > input[type="checkbox"] {
  visibility: visible;
}

.week_card-checkbox {
  margin-top: 3px;
  margin-right: 12px;
  height: 20px;
  flex-basis: 20px;
  width: 20px !important;
  flex-shrink: 0;
  border: 1px;
  flex-grow: 0;
  outline: none;
  border-radius: 2px;
}

.todo_border {
  border-bottom: 1px solid #d5d3d2;
  padding: 0.5rem 0px 0.5rem 0px;
}

.todo_border_nochange {
  border-bottom: 1px solid #d5d3d2;
  padding: 5px 0px;
}

.todo_border:last-child {
  border-bottom: none;
  padding: 5px 0px;
}

.week_card-announce-head {
  display: block;
  font-weight: bold;
  margin-bottom: 0px;
}

.week_card-announce {
  margin-block-start: 10px;
}

.week_card.card.carousel_card {
  width: 100%;
  height: 500px;
}

.selected .week_card-edit {
  margin-left: auto;
  visibility: visible;
  display: block;
  flex-shrink: 0;
}

.heading_weekcard_learners {
  font-size: 16px;
}
