/* multilevel-select is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
.multilevel-select{
  .dropdown-content {
    width: 455px;
  }
  .node {
    list-style: none;
    white-space: normal;
    padding: 4px;
    display: flex;
  }
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom::after{
  display: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after{
  display: none;
}

.multilevel-select .dropdown.radio-select {
  max-width: none;
  width: 100%;
}

.multilevel-select .tag-list {
  width: 100%;
  .tag-item{
    width: 97%;
    .search{
      width: inherit;
      border: none;
    }
  }
}

.multilevel-select .dropdown-trigger {
  border-radius: 0.25rem;
  display: flex !important;
  align-items: center;
}

.multilevel-select .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.multilevel-select .toggle {
  color: #555;
}

.multilevel-select .root {
  padding: 0px;
  margin: 0px;
}
.multilevel-select .tag {
  // display: none;
}

.infinite-scroll-component {
  height: 360px !important;
}

.node.disabled>* {
  color: #000000;
  cursor: pointer;
}

.node.disabled .node-label {
  color: #000000;
  cursor: not-allowed;
}

.node.disabled .radio-item {
  color: #000000;
  cursor: not-allowed;
}

span.node-label {
  word-break: break-all;
}