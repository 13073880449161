.text-left {
  text-align: left;
}
.sm-m-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.l-my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.comp__container {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
}
.assessment__item {
  background-color: transparent;
  border-width: 1px 0px 0px 0px !important;
  border-radius: 0px !important;
  border-style: solid;
  border-color: #999490;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  display: flex;
  flex-shrink: 1;
}
.assessment__comp {
  background-color: transparent;
  border-style: solid;
  border-color: #999490;
  border-radius: 10px;
  margin: 2.5px 0;
  background-color: #ffffff;
}
.component_row.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.announcement__comp {
  background-color: transparent;
  border-style: solid;
  border-color: #999490;
  border-radius: 10px;
  margin: 15px 0;
  background-color: #ffffff;
}
.Assessment___list {
  height: calc(2 * 4.8em);
  overflow: auto;
  padding: 0 5px 0 0;
  scrollbar-color: #999490 transparent;
  scrollbar-width: thin;
}
.Assessment___list::-webkit-scrollbar {
  width: 2px;
}
.Assessment___list::-webkit-scrollbar-track {
  background-color: transparent;
}
.Assessment___list::-webkit-scrollbar-thumb {
  background-color: #999490;
}
.view__all {
  border: none;
  font-size: 12px;
  color: #0000ee;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
}
.view__all:hover {
  border: none;
  font-size: 12px;
  color: #51247a;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
}

.refer-message {
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
}

.Assessment-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #4b4b4b;
}
.assessment-item__data {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.assessment__head {
  margin-bottom: 20px;
  margin-top: 20px;
}
.Assessment-h2 {
  font-size: 18px;
  flex: 1 1;
  margin: 0 1rem;
  color: #51247a;
  font-weight: bold;
}
@media (min-width: 580px) {
  .Annuouncement__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
  .carousel .ca_next {
    display: flex;
    margin-right: 0px !important;
  }
  .carousel .ca_prev {
    display: flex;
    margin-left: 0px !important;
  }
}
@media (min-width: 1000px) {
  .l-w-35 {
    width: 35%;
  }
  .announcement__comp {
    background-color: transparent;
    border-style: none;
    border-radius: 10px;
    margin: 20px 0;
  }
  .assessment__comp {
    background-color: transparent;
    border-style: none;
    border-radius: 0px;
    margin: 20px 0;
  }
  .component_row.row {
    display: flex;
    flex-direction: row;
    padding: 0px;
  }
  .comp__container {
    border-radius: 10px;
    border-style: solid;
    border-color: #999490;
    border-width: 1px;
    padding: 0;
    margin: 0 0 31px 0;
    background: #ffffff;
  }
  .assessment__comp {
    background-color: transparent;
    border-right-style: solid;
    border-color: #999490;
  }
  .assessment__head {
    margin-top: 0px;
  }
}

.assessment-item__date {
  font-size: 14px;
  font-weight: bold;
}
.assessment-item__title {
  font-size: 14px;
  font-weight: normal;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}
.assessment-item__title:hover {
  font-size: 14px;
  font-weight: normal;
  color: #51247a;
  text-decoration: underline;
  cursor: pointer;
}
.announcement-item__title {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  font-style: italic;
}
.margin-block-none {
  margin-block-start: 5px;
  margin-block-end: 0px;
}

.carousel .slider-wrapper.axis-horizontal .slider .selected + li {
  display: flex;
  align-items: flex-start;
  pointer-events: none;
}

.carousel .slider-wrapper.axis-horizontal .slider li {
  display: flex;
  align-items: flex-end;
  pointer-events: none;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide .ca_container {
  width: 75%;
  height: 500px;
  text-align: left;
}

.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card
  .cl_checkmark {
  background-color: #e5e5e5;
}

.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card
  .form-check-label a {
    color: #000;
    text-decoration: none;
}

.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card {
  border: 0px !important;
  border-width: 0px !important;
  border-radius: 10px;
  background-color: #e5e5e5 !important;
}

.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card
  .week_card-header {
  background-color: #d0d0d0 !important;
  color: #ffffff !important;
  border-radius: 10px 10px 0 0;
}

.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card
  .week_card-body {
  background-color: #e5e5e5;
}

.carousel .slider-wrapper.axis-horizontal .slider .selected .ca_container {
  display: flex;
  justify-content: center;
  padding: 0 20px 0 20px;
  width: 100%;
  pointer-events: all;
}

.carousel .slider-wrapper {
  display: flex;
  justify-content: center;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  width: 35%;
}

.carousel .ca_next,
.carousel .ca_prev {
  position: absolute;
  top: 43%;
  bottom: 43%;
  z-index: 3;
  user-select: none;
}
.carousel .ca_prev {
  left: 0;
  margin-left: 30px;
  text-align: center;
}

.carousel .ca_next {
  right: 0;
  margin-right: 30px;
  text-align: center;
}

@media (max-width: 1000px) {
  .carousel .ca_prev,
  .carousel .ca_next {
    display: block;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide .ca_container {
    width: 100%;
    text-align: left;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .selected .ca_container {
    display: flex;
    justify-content: center;
    padding: 0px;
    width: 88%;
    align-self: center;
  }

  .carousel .slider-wrapper {
    display: flex;
    justify-content: center;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .carousel .ca_prev,
  .carousel .ca_next {
    display: none;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide .ca_container {
    width: 100%;
    text-align: left;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .selected .ca_container {
    display: flex;
    justify-content: center;
    padding: 0px;
    width: 100%;
  }

  .carousel .slider-wrapper {
    display: flex;
    justify-content: center;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    width: 100%;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  left: 24px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* Create a custom checkbox */
.cl_checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #999490;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .cl_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .cl_checkmark {
  background-color: #999490;
}

/* Create the checkmark/indicator (hidden when not checked) */
.cl_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .cl_checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .cl_checkmark:after {
  width: 5.5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-left: 4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.no_margin.ca_prev {
  margin: 0;
  width: 65px;
}
.no_margin.ca_next {
  margin: 0;
  width: 65px;
}
.form-check-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: block;
  width: 100%;
  margin-left: 0.5rem;
}
.selected .form-check-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  display: block;
}
