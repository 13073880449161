.react-calendar {
  border-radius: 10px;
  width: 250px !important;
  position: absolute;
  margin-left: -32px !important;
  margin: 11px;
  z-index: 2;
  box-shadow: 0px 3px 6px #272d3b4a;
  border: 1px solid #51247a !important;
  height: auto !important;
  overflow-y: clip !important;
}
.react-calendar__tile {
  line-height: 14px !important;
  font-family: "OpenSans";
  font-size: 13px;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background-color: grey;
  border-radius: 50%;
  color: #ffffff;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: grey;
  border-radius: 50%;
  color: #ffffff;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__tile--active {
  background: #51247a !important;
  color: white !important;
  border-radius: 50%;
}
.react-calendar__tile--now {
  background: #ffffff;
}
.react-calendar__navigation {
  margin-bottom: 0px !important;
}
.button.react-calendar__tile.react-calendar__month-view__days__day
  :enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #51247a !important;
  color: white;
  border-radius: 50%;
}
.react-calendar__navigation button:disabled {
  background-color: #ffffff;
}
.react-calendar__navigation button:disabled:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}
.react-calendar__tile:disabled {
  background-color: #c8c8c8;
  cursor: not-allowed;
}
.react-calendar__tile:disabled:hover {
  background-color: #c8c8c8;
  border: none !important;
  cursor: not-allowed;
}
.react-calendar__tile.react-calendar__month-view__days__day:disabled:hover {
  cursor: not-allowed;
  border-radius: 0;
  background-color: #c8c8c8 !important;
}
.react-calendar__decade-view__years__year.react-calendar__tile:disabled {
  display: none;
}
// .react-calendar__tile:disabled {
//   visibility: hidden;
// }
.react-calendar__navigation button:enabled,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffffff;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ffffff;
}
button.react-calendar__navigation__label {
  color: #51247a !important;
  font-weight: bold;
  order: -1;
  font-family: "OpenSans";
  align-items: center;
  max-width: 90px;
  margin-right: auto;
}
.react-calendar__navigation button:disabled {
  cursor: not-allowed;
}
.react-calendar__navigation button:hover {
  border-radius: 50%;
  background: white !important;
}
.react-calendar__navigation button {
  min-width: 30px;
  background: white;
  border-radius: 50%;
}

.react-calendar__tile--active:hover {
  color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  font-size: 13px;
}
.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:enabled:hover {
  color: white !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: grey;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover {
  color: white !important;
}
.abbr[data-bs-original-title],
abbr[title] {
  text-decoration: none;
  cursor: default;
  font-family: "OpenSans";
  font-size: 10px;
}
svg.arrow_Icon {
  height: 15px;
}
.react-calendar__tile.react-calendar__month-view__days__day:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: grey !important;
  color: white;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile.react-calendar__tile {
  padding: 1.5em 0.5em !important;
}
.react-calendar__year-view {
  font-size: 10px;
}
.react-calendar__year-view__months:enabled:focus {
  background-color: black;
}
button.react-calendar__year-view__months__month:enabled:focus {
  cursor: pointer;
  border-radius: 50%;
  background-color: white !important;
  color: #51247a !important;
}
button.react-calendar__decade-view__years__year:enabled:focus {
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent !important;
  color: #51247a !important;
}
button.react-calendar__century-view__decades__decade:enabled:focus {
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent !important;
  color: #51247a !important;
}
.react-calendar__century-view {
  pointer-events: none;

  cursor: not-allowed !important;
}
.react-calendar__decade-view {
  font-size: 10px;
}
.react-calendar__tile--hasActive {
  background: transparent !important;
}
.calender__icon {
  width: 20px;
  margin-right: 10px;
  margin-left: 25px;
}
.react-calendar__tile--hasActive {
  background: transparent !important;
}

button.react-calendar__navigation__label:focus-visible {
  border: 1px solid;
}

button.react-calendar__navigation__arrow:focus-visible {
  outline-style: double;
}